import React from 'react'
import emailjs from 'emailjs-com';
function EnquiryForm() {
    function sendEmail(e) {
        e.preventDefault(); //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_74r9413', 'template_y5w3hbo', e.target, 'agoloy_2h0Yp_6lI-')
            .then((result) => {
                alert('Thank you for your details, we will contact you soon !');
                window.location.reload()  // This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }
    return (
        <><div className='text-center' id='enquiryid'><h1 className='p-3 headingh1text my-5'>Enquiry Form</h1></div>
            <div className='container-fluid text-light d-flex contact-container' >

                <div className="form-text contact-field p-5 shadow my-2 mx-2">
                    <h1 className="form-item text-danger">ARENA ANIMATION RAIPUR</h1>
                    <div className="form-item d-flex">
                        <i className="fa-solid mx-2 mt-1 fa-phone"></i>
                        <p>(+91)-7714039789/ (+91) 9589233695</p>
                    </div>
                    <div className="form-item d-flex">
                        <i className="fa-solid mx-2 mt-1 fa-envelope"></i>
                        <p>arenaanimationraipur01@gmail.com</p>
                    </div><div className="form-item d-flex">
                        <i className="fa-solid mx-2 mt-1 fa-location-dot">
                        </i>
                        <p>Govind Kunj Civil Lines Raipur Chhattishgarh- India</p>
                    </div>
                </div>
                <div className='form-input contact-field p-2 shadow  my-2 mx-2'>
                    <form onSubmit={sendEmail}>
                        <input required type="text" id="fname" name="name" placeholder="Your Name.." />
                        <input required type="email" id="lname" name="email" placeholder="Your Email.." />
                        <input required type="tel" pattern="[0-9]{10}" id="lname" name="number" placeholder="Your Number.." />

                        <select id="country" name="course" required>
                            <option value="">-select-</option>
                            <option value="Animation, VFx">Animation, VFx</option>
                            <option value="Graphic Visual Design">Graphic Visual Design(GVD)</option>
                            <option value="UI/UX Design">User Interface Design(UI Design)</option>
                            <option value="Web Design & Development">Web Design & Development(WDD)</option>
                            <option value="Game Design And Development">Game Design and Development</option>
                            <option value="Print & Publish">Digital Marketing</option>
                            <option value="Short Term Courses">Short Term Courses</option>
                        </select>
                        <textarea id="subject" name="message" placeholder="Write something.." ></textarea>
                        <input type="submit" value="send" className='my-5' />
                    </form>

                </div>
            </div>


        </>
    )
}

export default EnquiryForm;